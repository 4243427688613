<template>
    <div v-if="management.use" class="dashboard">
        <div class="pagetitle l-2-00-m--pc l-1-01-m--sp">
            <div>
                <h1>
                    {{ $_pagetitle }}
                </h1>
            </div>
            <div>
                <Button
                    v-if="historyLink"
                    :text="`${management.title}${textset[management.textset].history}${textset[management.textset].list}を見る`"
                    type="textlink"
                    @action="$_goHistory()"
                />
            </div>
        </div>
        <div class="l-2-00-m--pc l-1-00-m--sp" :class="$style.buttonAreaTop">
            <div>
                <Button
                    v-if="$store.getters.getManagements[name].button.create && !history"
                    :text="`${title}を${textset[management.textset].create}する`"
                    type="create"
                    @action="$_goCreate()"
                />
            </div>
            <div class="isSpNone">
                <CsvDownload
                    button-text="CSVダウンロード"
                    :column="column"
                    :file-name="`${name}-list-${$_getDate().db}`"
                    :list="row"
                />
                <PdfDownload
                    button-text="PDFダウンロード"
                    :column="column"
                    doc-definition="list"
                    :file-name="`${name}-list-${$_getDate().db}`"
                    :list="row"
                    :name="name"
                    :option="pdfDownloadOption"
                    :title="$_pagetitle"
                />
            </div>
        </div>
        <List
            :column="column"
            :history="history"
            :name="name"
            :read="readData"
            :text="textset[management.textset]"
            @result="row = $event"
            @modal="$_modal($event)"
        />
        <div v-if="history" :class="$style.buttonAreaBottom">
            <Button
                type="back"
                @action="$_buttonBack(name)"
            />
        </div>
        <div v-else-if="$store.getters.getManagements[name].button.back" :class="$style.buttonAreaBottom">
            <Button
                type="back"
                @action="$_buttonBack($store.getters.getManagements[name].button.back)"
            />
        </div>
        <Modal
            :modal="modal"
        />
    </div>
</template>

<script>
    "use strict";

    import CsvDownload from "@/components/CsvDownload.vue";
    import CsvUpload from "@/components/CsvUpload.vue";
    import PdfDownload from "@/components/PdfDownload.vue";

    import dashboard from "@/mixins/dashboard.js";
    import datetime from "@/mixins/datetime.js";
    import list from "@/mixins/list.js";

    export default {
        "components": {
            CsvDownload,
            CsvUpload,
            PdfDownload,
        },
        "mixins": [
            dashboard,
            datetime,
            list,
        ],
        "props": {
            "history": {
                "type": Boolean,
                "required": false,
            },
            "historyLink": {
                "type": Boolean,
                "required": false,
            },
            "id": {
                "type": Array,
                "required": false,
                "default": function() {
                    return [];
                },
            },
            "name": {
                "type": String,
                "required": false,
            },
        },
        data() {
            return {
                "watch": "",
            };
        },
        "computed": {
            // managementでcolumnWidthの設定をチェック
            pdfDownloadOption() {
                let option = {};
                if (this.management.columnWidth) {
                    option = {
                        "columnWidth": this.management.columnWidth,
                    };
                }
                return option;
            },
        },
        "watch": {
            $route() {
                if (this.watch !== this.name) {
                    this.$_created();
                }
            },
            "history": function() {
                this.$_created();
            },
        },
    };
</script>

<style lang="scss" module>
    .buttonAreaTop {
        > div {
            > * {
                margin-bottom: 48px;
                @include sp {
                    margin-bottom: 32px;
                }
            }
        }
    }
    .buttonAreaBottom {
        margin: 72px 0 0;
    }
</style>
