<template>
    <div :class="$style.csvUpload">
        <Button
            v-if="module.use"
            :text="buttonText"
            type="upload"
            @action="fileOpen()"
        />
        <input ref="csvFile" type="file" accept=".csv" style="display:none;" @change="selectedFile(arguments[0])">
    </div>
</template>

<script>
    "use strict";

    import Button from "@/components/Button.vue";

    import storage from "@/mixins/storage.js";

    export default {
        "components": {
            Button,
        },
        "mixins": [
            storage,
        ],
        "props": {
            "buttonText": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "file": [
                    {
                        "data": "",
                    },
                ],
                "fileName": [],
                // "module": this.$store.getters.getModules.csvUpload,
                "module": {
                    "use": true,
                },
            };
        },
        // created() {
        //     this.$store.watch(
        //         (state, getters) => getters.getModules,
        //         () => {
        //             this.module = this.$store.getters.getModules.csvUpload;
        //         }
        //     );
        // },
        "methods": {
            fileOpen() {
                this.$refs.csvFile.click();
            },
            selectedFile(e) {
                e.preventDefault();
                const file = e.target.files[0];
                if (file) {
                    this.fileName[0] = this.createFileName(file.name);
                }
                this.file[0].data = file;

                this.csvUpload();
            },
            createFileName(filesName) {
                const strong = 1000;
                const name = new Date().getTime().toString(16) + Math.floor(strong * Math.random()).toString(16);
                const extension = this.getExtension(filesName);
                return `${name}.${extension}`;
            },
            getExtension(string) {
                const extension = string.split(".").slice(-1)[0].toLowerCase();
                return extension;
            },
            csvUpload() {
                const fileProcessData = {
                    "file": this.file,
                    "fileName": this.fileName,
                };
                this.$_fileUpload(fileProcessData),
                alert(`${this.fileName}をupload`);
            },
        },
    };
</script>

<style lang="scss" module>
    .csvUpload {
        display: inline-block;
    }
</style>
