"use strict";

export default {
    data() {
        return {
            "optionData": {},
        };
    },
    "methods": {
        //optionを優先順位に沿ってset
        $_setOptionData(defaultData) {
            if (this.module.option[this.name]) {
                // moduleで指定があった場合（最優先）
                this.optionData = this.module.option[this.name];
            }
            else if (Object.keys(this.option).length) {
                let flag = false;
                for (const key in this.option) {
                    // propsでoptionを受け取らなかった場合falseが入ってしまうため判定
                    if (!flag) {
                        if (this.option[key]) {
                            flag = !flag;
                        }
                    }
                }
                if (flag) {
                    // propsでoptionを受け取った場合
                    this.optionData = this.option;
                }
                else {
                    // propsでoptionを受け取らなかった場合
                    this.optionData = defaultData;
                }
            }
            else {
                // moduleにもpropsのoptionにも設定がない場合defaultがsetされる
                this.optionData = defaultData;
            }
        },
    },
};
