<template>
    <Button
        v-if="module.use"
        :text="buttonText"
        type="download"
        @action="onCsvDownload()"
    />
</template>

<script>
    "use strict";

    import Button from "@/components/Button.vue";

    const text = {
        // "orderId": "注文番号",
    };

    export default {
        "components": {
            Button,
        },
        "props": {
            "buttonText": {
                "type": String,
                "required": true,
            },
            "column": {
                "type": Array,
                "required": true,
            },
            "fileName": {
                "type": String,
                "required": false,
                "default": "",
            },
            "list": {
                "type": Array,
                "required": true,
            },
        },
        data() {
            return {
                "module": this.$store.getters.getModules.csvDownload,
            };
        },
        created() {
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.csvDownload;
                }
            );
        },
        "methods": {
            createCsvData(data) {
                let csvData = "";
                // 行の追加
                const addData = (i) => {
                    for (const key of this.column) {
                        if (key !== "&nbsp") {
                            const str = String(data[i][key]).replace(/\r?\n|,/g, "");
                            const val = i === 0 ? text[key] ? text[key] : key : str !== "null" ? str : "";
                            csvData += `${val},`;
                        }
                    }
                    csvData += "\n";
                };
                // データの追加
                if (data) {
                    // 1行目
                    addData(0);
                    // 2行目以降
                    for (const i in data) {
                        addData(i);
                    }
                }
                return csvData;
            },
            onCsvDownload() {
                const data = this.createCsvData(this.list);
                const fileName = this.fileName ? this.fileName : "file";
                const csv = `\ufeff${data}`;
                const blob = new Blob([csv], {
                    "type": "text/csv;charset=utf-8",
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName + ".csv";
                link.click();
            },
        },
    };
</script>
