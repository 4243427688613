<template>
    <Button
        v-if="module.use"
        :text="buttonText"
        type="download"
        @action="onPdfDownload()"
    />
</template>

<script>
    "use strict";

    import pdfmake from "pdfmake/build/pdfmake";
    import "pdfmake/build/vfs_fonts.js";

    import docDefinition from "@/settings/docDefinition.js";
    import field from "@/settings/field.js";

    import Button from "@/components/Button.vue";

    import optionData from "@/mixins/optionData.js";

    export default {
        "components": {
            Button,
        },
        "mixins": [
            optionData,
        ],
        "props": {
            "buttonText": {
                "type": String,
                "required": true,
            },
            "column": {
                "type": Array,
                "required": true,
            },
            "docDefinition": {
                "type": String,
                "required": true,
            },
            "fileName": {
                "type": String,
                "required": false,
                "default": "",
            },
            "filesUrl": {
                "type": String,
                "required": false,
                "default": "",
            },
            "list": {
                "type": Array,
                "required": true,
            },
            "name": {
                "type": String,
                "required": false,
            },
            "option": {
                "type": Object,
                "required": false,
                "default": function () {
                    return {};
                },
            },
            "title": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "module": this.$store.getters.getModules.pdfDownload,
            };
        },
        "watch": {
            "column": function() {
                this.$_setOptionData(this.defaultOptionData());
            },
        },
        created() {
            this.$_setOptionData(this.defaultOptionData());
            this.$store.watch(
                (state, getters) => getters.getModules,
                () => {
                    this.module = this.$store.getters.getModules.pdfDownload;
                }
            );
        },
        "methods": {
            // columnWidthのdefaultをset
            defaultOptionData() {
                const width = [];
                let optionData = {};
                for (const key of this.column) {
                    if (key !== "&nbsp") {
                        width.push("auto");
                    }
                }
                optionData = {
                    "columnWidth": width,
                };
                return optionData;
            },
            // 全角から半角へ変換
            replaceCharHalf(val) {
                if (val) {
                    const convert = String(val).replace(/[！-～]/g,
                                                        (v) => String.fromCharCode(v.charCodeAt(0) - 0xFEE0)
                    );
                    // 文字コードシフトで対応できない文字を変換
                    return convert.replace(/”/g, "\"")
                        .replace(/’/g, "'")
                        .replace(/‘/g, "`")
                        .replace(/￥/g, "\\")
                        .replace(/〜/g, "~");
                }
                else {
                    return "";
                }
            },
            resetPdf(option) {
                if (option.pageOrientation) {
                    docDefinition[this.docDefinition].pageOrientation = option.pageOrientation;
                }
                docDefinition[this.docDefinition].content = [];
                docDefinition[this.docDefinition].images = {};
            },
            addPdfContent(data) {
                docDefinition[this.docDefinition].content.push(data);
            },
            addPdfImage(data) {
                docDefinition[this.docDefinition].images = data;
            },
            pdfDownload(fileName) {
                pdfmake.fonts = {
                    "IPAgothic": {
                        "normal": "ipagp.ttf",
                        "bold": "ipagp.ttf",
                        "italics": "ipagp.ttf",
                        "bolditalics": "ipagp.ttf",
                    },
                    "IPAmincho": {
                        "normal": "ipamp.ttf",
                        "bold": "ipamp.ttf",
                        "italics": "ipamp.ttf",
                        "bolditalics": "ipamp.ttf",
                    },
                };
                const doc = Object.assign({}, JSON.parse(JSON.stringify(docDefinition[this.docDefinition])));
                pdfmake.createPdf(doc).download(fileName);
            },
            onPdfDownload() {
                const empty = "&nbsp";
                const table = [];

                // テーブルを生成
                table[0] = [];
                // thを追加
                for (const key of this.column) {
                    if (key !== empty) {
                        table[0].push(field[key].head);
                    }
                }
                // tdを追加（複数行対応）
                const image = {};
                const imageWidth = this.optionData.columnWidth[0];
                let imageNumber = 0;
                for (const data of this.list) {
                    const tr = [];
                    for (const key of this.column) {
                        if (key && key !== empty) {
                            if (data.deletedAt) {
                                tr.push(
                                    {
                                        "style": {
                                            "decoration": "lineThrough",
                                            "fillColor": "#ddd",
                                        },
                                        "text": this.replaceCharHalf(data[key]),
                                    }
                                );
                            }
                            else if (key === "image" && data[key]) {
                                const imageKey = `image${imageNumber}`;
                                image[imageKey] = data[key][imageNumber];
                                tr.push(
                                    {
                                        "image": imageKey,
                                        "width": imageWidth,
                                    }
                                );
                                imageNumber++;
                            }
                            else {
                                const str = data[key] ?? "";
                                const val = field[key].unit ? `${str}${field[key].unit}` : str;
                                tr.push(this.replaceCharHalf(String(val)));
                            }
                        }
                    }
                    table.push(tr);
                }
                // 横向きでPDFをリセット
                this.resetPdf({
                    "pageOrientation": "landscape", 
                });
                // PDFにtitleを追加
                this.addPdfContent({
                    "text": this.title,
                    "style": "title",
                });
                // PDFにデータテーブルを追加
                this.addPdfContent({
                    "table": {
                        "body": table,
                        "widths": this.optionData.columnWidth,
                    },
                    "style": "table",
                });
                // PDFにimageを追加
                this.addPdfImage(image);
                // PDFを出力
                this.pdfDownload(this.fileName);
            },
        },
    };
</script>
