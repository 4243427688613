"use strict";

export default {
    "list": {
        "pageSize": "A4",
        "pageOrientation": "portrait",
        "pageMargins": 32,
        "content": [],
        "images": {},
        "defaultStyle": {
            "font": "IPAgothic",
        },
        "styles": {
            "title": {
                "bold": true,
                "fontSize": 18,
                "lineHeight": 1.2,
                "margin": [0, 0, 0, 8],
            },
            "text": {
                "fontSize": 12,
                "lineHeight": 1.3,
                "margin": [0, 0, 0, 16],
            },
            "table": {
                "fontSize": 11,
                "lineHeight": 1.3,
            },
        },
    },
};
